const types = {
    SET_SOURCE: 'SET_SOURCE',

    SET_LIST: 'SET_LIST',

    SET_LEAD: 'SET_LEAD',

    SET_SOURCES: 'SET_SOURCES',

    ADD_SOURCE: 'ADD_SOURCE',

    SET_LISTS: 'SET_LISTS',

    ADD_LIST: 'ADD_LIST',

    SET_LEADS: 'SET_LEADS',

    SET_OVERALL_SOURCES: 'SET_OVERALL_SOURCES',

    SET_RUN_DATA_UPDATE: 'SET_RUN_DATA_UPDATE',

    SET_NEW_IMPORT: 'SET_NEW_IMPORT',

    SET_LEAD_COLUMNS: 'SET_LEAD_COLUMNS'
}

export default types