import React from 'react'
import { CampaignProvider } from '../../Contexts/CampaignContext'
import Campaign from './Campaign'

const CampaignWrapper = () => {
    return (
        <CampaignProvider>
            <Campaign />
        </CampaignProvider>
    )
}

export default CampaignWrapper
