const types = {
    SET_SEGMENT: 'SET_SEGMENT',

    SET_SEGMENTS: 'SET_SEGMENTS',

    SET_SEGMENT_LEAD: 'SET_SEGMENT_LEAD',

    SET_SEGMENT_LEADS: 'SET_SEGMENT_LEADS',

    SET_NEW_SEGMENT_SOURCES: 'SET_NEW_SEGMENT_SOURCES',

    SET_NEW_SEGMENT_LISTS: 'SET_NEW_SEGMENT_LISTS',

    SET_NEW_SEGMENT_CONDITIONS: 'SET_NEW_SEGMENT_CONDITIONS',

    SET_SEGMENT_LEADS_COLUMNS: 'SET_SEGMENT_LEADS_COLUMNS',

    ADD_EXPORT: 'ADD_EXPORT',

    REMOVE_EXPORT: 'REMOVE_EXPORT',

    SET_EXPORTS: 'SET_EXPORTS',

    SET_IS_SEGMENT_SYNCED: 'SET_IS_SEGMENT_SYNCED'
}

export default types
