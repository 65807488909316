import React from 'react';

const DisabledValue = ({value}) => {
    return (
        <div style={{opacity: .7}}>
            {value}
        </div>
    );
};

export default DisabledValue;
