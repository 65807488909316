import React from 'react'
import { lookupStatusClassMap, snakeToTitle } from '../helpers'

const LookupStatus = ({id, label}) => {

    const statusMap = lookupStatusClassMap()

    return (
        <div className={`chipLike ${statusMap[id]}`}>
            <span className='chipLikeLabel'>{snakeToTitle(label)}</span>
        </div>
    )
}

export default LookupStatus
