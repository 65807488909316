import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import Types from './types'

const INITIAL_STATE = {
    segment: null,
    segments: [],
    segmentLead: null,
    segmentLeads: [],
    newSegmentOptions: {
        sources: [],
        lists: [],
        conditions: [],
        empties: []
    },
    segmentLeadsColumns: [],
    activeExports: [],
    isSegmentSynced: true
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Types.SET_SEGMENT:
            if (action.payload)
                return {
                    ...state,
                    segment: action.payload
                }
            else {
                return {
                    ...state,
                    segment: action.payload,
                    segmentLead: null
                }
            }

        case Types.SET_SEGMENTS:
            return {
                ...state,
                segments: action.payload
            }

        case Types.SET_SEGMENT_LEAD:
            return {
                ...state,
                segmentLead: action.payload
            }

        case Types.SET_SEGMENT_LEADS:
            return {
                ...state,
                segmentLeads: action.payload
            }

        case Types.SET_NEW_SEGMENT_SOURCES:
            return {
                ...state,
                newSegmentOptions: { ...state.newSegmentOptions, sources: action.payload }
            }

        case Types.SET_NEW_SEGMENT_LISTS:
            return {
                ...state,
                newSegmentOptions: { ...state.newSegmentOptions, lists: action.payload }
            }

        case Types.SET_NEW_SEGMENT_CONDITIONS:
            return {
                ...state,
                newSegmentOptions: { ...state.newSegmentOptions, conditions: action.payload.conditions, empties: action.payload.empties }
            }

        case Types.SET_SEGMENT_LEADS_COLUMNS:
            return {
                ...state,
                segmentLeadsColumns: action.payload
            }

        case Types.ADD_EXPORT:
            return {
                ...state,
                activeExports: [...state.activeExports, action.payload]
            }

        case Types.REMOVE_EXPORT:
            return {
                ...state,
                activeExports: [...state.activeExports.filter(x => x.url !== action.payload.url)]
            }

        case Types.SET_EXPORTS:
            return {
                ...state,
                activeExports: action.payload
            }

        case Types.SET_IS_SEGMENT_SYNCED:
            return {
                ...state,
                isSegmentSynced: action.payload
            }

        default:
            return state
    }
}

const persistConfig = {
    key: 'segments',
    storage
}

export default persistReducer(persistConfig, reducer)
