import Types from './types'

export const setSegment = segment => ({
    type: Types.SET_SEGMENT,
    payload: segment
})

export const setSegments = segments => ({
    type: Types.SET_SEGMENTS,
    payload: segments
})

export const setSegmentLead = lead => ({
    type: Types.SET_SEGMENT_LEAD,
    payload: lead
})

export const setSegmentLeads = leads => ({
    type: Types.SET_SEGMENT_LEADS,
    payload: leads
})

export const setNewSegmentSources = lead => ({
    type: Types.SET_NEW_SEGMENT_SOURCES,
    payload: lead
})

export const setNewSegmentLists = lead => ({
    type: Types.SET_NEW_SEGMENT_LISTS,
    payload: lead
})

export const setNewSegmentConditions = (conditions, empties) => ({
    type: Types.SET_NEW_SEGMENT_CONDITIONS,
    payload: { conditions, empties }
})

export const setSegmentLeadsColumns = leadsColumns => ({
    type: Types.SET_SEGMENT_LEADS_COLUMNS,
    payload: leadsColumns
})

export const addExport = exp => ({
    type: Types.ADD_EXPORT,
    payload: exp
})

export const removeExport = exp => ({
    type: Types.REMOVE_EXPORT,
    payload: exp
})

export const setExports = exps => ({
    type: Types.SET_EXPORTS,
    payload: exps
})

export const setIsSegmentSynced = value => ({
    type: Types.SET_IS_SEGMENT_SYNCED,
    payload: value
})
