import React from 'react'
import Loader from 'react-loader-spinner'

const ListLoader = () => {
    return (
        <tr>
            <td colSpan="100%">
                <Loader
                    type="Bars"
                    color="#0576ED"
                    style={{
                        paddingTop: '10px',
                        textAlign: 'center'
                    }}
                    height={30}
                    width={30}
                />
            </td>
        </tr>
    )
}

export default ListLoader
