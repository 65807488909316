import React, { useMemo, useState } from 'react'
import Chip from '@material-ui/core/Chip'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/Clear'
import { snakeToTitle } from '../../helpers'

const SelectRow = props => {
    const {
        items,
        selectedItems,
        entityLabel='Item',
        onChange
    } = props

    const [setMenuAnchorEl, onSetMenuAnchorElChange] = useState(null)

    const availableItems = useMemo(() => {
        return items.filter(item => !selectedItems.includes(item.id))
    }, [items, selectedItems])

    const addItem = id => {
        onChange([...selectedItems, id])
    }

    const removeItem = id => {
        onChange(selectedItems.filter(i => i !== id))
    }

    return (
        <div className="filterRow">
            <div className="buttons">
                {/*<Chip label={entityLabel}/>*/}
                {/*<Chip label='Equals'/>*/}
                {selectedItems.map((itemId, index) => {
                    const modelByValue = items.find(i => i.id === itemId)
                    const label = modelByValue ? modelByValue.name : snakeToTitle(itemId.toString())
                    return (
                        <Chip
                            key={index}
                            label={label}
                            onDelete={() => removeItem(itemId)}
                        />
                    )
                })}
                <Chip
                    disabled={!availableItems.length}
                    label={`Add ${entityLabel}`}
                    icon={<AddCircleOutlineIcon />}
                    onClick={e => { onSetMenuAnchorElChange(e.target) }}
                />
                <Menu
                    anchorEl={setMenuAnchorEl}
                    open={Boolean(setMenuAnchorEl)}
                    onClose={() => onSetMenuAnchorElChange(null)}
                >
                    {
                        availableItems.map((option) => (
                            <MenuItem
                                key={option.id}
                                value={option}
                                onClick={(e) => {
                                    onSetMenuAnchorElChange(null)
                                    addItem(option.id)
                                }}>
                                {option.name}
                            </MenuItem>
                        ))
                    }
                </Menu>
            </div>
            {
                !!selectedItems.length && (
                    <div className="remove">
                        <IconButton
                            size='small'
                            onClick={() => onChange([])}>
                            <ClearIcon />
                        </IconButton>
                    </div>
                )
            }
        </div>
    )
}

export default SelectRow
