import React from 'react'
import Loader from 'react-loader-spinner'

const PageLoader = () => {
    return (
        <div>
            <Loader
                type="Bars"
                color="#0576ED"
                style={{
                    paddingTop: '10px',
                    textAlign: 'center'
                }}
                height={30}
                width={30}
            />
        </div>
    )
}

export default PageLoader
